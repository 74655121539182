<template>
  <v-container>
    <div>
      <router-view></router-view>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
};
</script>
